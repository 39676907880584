// components/Sidebar.jsx
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import {
  SpaceDashboardOutlined,
  ChatOutlined,
  History,
  SettingsOutlined,
  LogoutOutlined,
} from '@mui/icons-material';
import Logo from '../../assets/image/logo.png';
import Avatar from '../../assets/image/avatar-menu.png';
import LogoNotext from '../../assets/image/Logo-notext.png';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setConnection, clearConnection } from '../Redux/connectionSlice';
import * as signalR from '@microsoft/signalr';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setToggleAccordion } from '../Redux/connectionSlice';
import { useMediaQuery } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import createAxiosClient from '../../utils/axiosClient';
import '../../css/Menuleft.css';
import { getParamConfig } from '../../utils/nomal';
import { setUpdateFullName } from '../Redux/connectionSlice';
import { useTranslation } from "react-i18next";
export default function Sidebar({ sidebarToggle, setSidebarToggle }) {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState('Dashboard');
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const [connectionState, setConnectionState] = useState(null);
  const [fullName, setFullName] = useState('');
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const isFullName = useSelector((state) => state.connection.fullName);
  const toggleAccordion = useSelector(
    (state) => state.connection.toggleAccordion
  );
  const [isAccordionExpanded, setIsAccordionExpanded] =
    useState(toggleAccordion);
  const isPC = useMediaQuery('(min-width:1024px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosClient = createAxiosClient(navigate);
  const { t } = useTranslation();
  useEffect(() => {
    const apiUrl = getParamConfig('API_URL');
    const hubUrl = `${apiUrl}/chathub`;
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl)
      .withAutomaticReconnect()
      .build();
    newConnection.start().then(() => {
      dispatch(setConnection(newConnection));
      setConnectionState(newConnection);
      updateCon(newConnection);
    });
  }, []);

  useEffect(() => {
    if (isFullName) {
      fetchUserInfo();
      dispatch(setUpdateFullName(false));
    }
    fetchUserInfo();
  }, [isFullName]);
  const fetchUserInfo = async () => {
    const userId = localStorage.getItem('UserId');
    const body = { UserId: userId };
    const result = await axiosClient.post('/user/detail', body);
    setFullName(result.FullName || '');
    localStorage.setItem('nameTeller', result.FullName);
  };
  const updateCon = async (connectionState) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No token found');
        navigate('/Login');
        return;
      }
      const fetchData = async () => {
        const connectionId = { ConnectionId: connectionState.connectionId };
        const responseUpdate = await axiosClient.post(
          '/chatroom/onconnection',
          connectionId
        );
      };
      fetchData();
    } catch (error) {
      console.error('Error: ', error.message);
    }
  };
  const handleLogoutClick = () => {
    setOpenLogoutDialog(true); // Open the modal
  };

  const handleCloseDialog = () => {
    setOpenLogoutDialog(false); // Close the modal
  };

  const handleConfirmLogout = () => {
    handleLogout();
    setOpenLogoutDialog(false); // Close the modal after logout
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.matchMedia('(max-width: 1024px)').matches);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleItemClick = (item) => {
    setActiveItem(item);
    if (isMobileOrTablet) {
      setSidebarToggle(!sidebarToggle);
    }
  };

  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    setIsAccordionExpanded(toggleAccordion);
  }, [toggleAccordion]);

  const handleAccordionToggle = () => {
    dispatch(setToggleAccordion(!isAccordionExpanded));
  };

  const handleLogout = async () => {
    const result = await axiosClient.post('/auth/logout');
    localStorage.removeItem('authToken');
    navigate('/Login');
  };

  return (
    <div
      className={`side-bar overflow-y-auto custom-scrollbar fixed flex flex-col justify-between top-0 left-0 h-full w-[14%] py-1 bg-[#041E42] text-white transform ${
        sidebarToggle ? 'active' : ''
      } transition-all duration-300 ease-in-out`}
    >
      <div className="sidebar-toggle px-2 pt-5">
        <div className="flex justify-between">
          <Link
            to="/"
            className={`${
              !sidebarToggle && isPC && 'flex justify-end'
            } my-2 mb-10 flex-1`}
          >
            {isPC ? (
              <img
                src={sidebarToggle ? Logo : LogoNotext}
                alt=""
                className={
                  sidebarToggle
                    ? 'w-[150px] my-0 mx-auto'
                    : 'mr-3 object-cover  max-w-[39px] max-h-[39px]'
                }
              />
            ) : (
              <img src={Logo} alt="" className="w-[150px] my-0 mx-auto" />
            )}
          </Link>
          {/* <div onClick={handleSidebarToggle} className="hidden close-menu-left">
            <MenuOpenIcon />
          </div> */}
        </div>

        <ul className={!sidebarToggle && isPC && 'flex flex-col items-end'}>
          <li
            className={`menu-item ${!sidebarToggle && isPC && 'w-[30%]'} ${
              location.pathname === '/' ? 'bg-[#1D3455]' : ''
            } mb-2 rounded-lg hover:shadow hover:bg-[#1D3455] py-2`}
            onClick={() => handleItemClick('Dashboard')}
          >
            <Link
              to="/"
              className={`px-3 py-2 text-white flex items-center gap-5 ${
                !sidebarToggle && isPC && 'justify-center'
              }`}
            >
              <SpaceDashboardOutlined
                className="inline-block !w-5 !h-5"
                color="#C6DDFF"
              />
              {isPC ? (
                <span
                  className={`${
                    sidebarToggle
                      ? 'text-[#C6DDFF] text-sm font-medium'
                      : 'hidden'
                  }`}
                >
                    {t("dashboard")}
                </span>
              ) : (
                <span className="text-[#C6DDFF] text-sm font-medium">
                  {t("dashboard")}
                </span>
              )}
            </Link>
          </li>

          <li
            className={`menu-item ${!sidebarToggle && isPC && 'w-[30%]'} ${
              location.pathname === '/Chat' ? 'bg-[#1D3455]' : ''
            } mb-2 rounded-lg hover:shadow hover:bg-[#1D3455] py-2`}
            onClick={() => handleItemClick('Live chat')}
          >
            <Link
              to="/Chat"
              className={`px-3 py-2 text-white flex items-center gap-5 ${
                !sidebarToggle && isPC && 'justify-center'
              }`}
            >
              <ChatOutlined className="inline-block !w-5 !h-5" />
              {isPC ? (
                <span
                  className={`${
                    sidebarToggle
                      ? 'text-[#C6DDFF] text-sm font-medium'
                      : 'hidden'
                  }`}
                >
                  {t("livechat")}
                </span>
              ) : (
                <span className="text-[#C6DDFF] text-sm font-medium">
                  {t("livechat")}
                </span>
              )}
            </Link>
          </li>

          <li
            className={`menu-item ${!sidebarToggle && isPC && 'w-[30%]'} ${
              location.pathname === '/History' ? 'bg-[#1D3455]' : ''
            } mb-2 rounded-lg hover:shadow hover:bg-[#1D3455] py-2`}
            onClick={() => handleItemClick('History')}
          >
            <Link
              to="/History"
              className={`px-3 py-2 text-white flex items-center gap-5 ${
                !sidebarToggle && isPC && 'justify-center'
              }`}
            >
              <History className="inline-block !w-5 !h-5" />
              {isPC ? (
                <span
                  className={`${
                    sidebarToggle
                      ? 'text-[#C6DDFF] text-sm font-medium'
                      : 'hidden'
                  }`}
                >
                   {t("history")}
                </span>
              ) : (
                <span className="text-[#C6DDFF] text-sm font-medium">
                   {t("history")}
                </span>
              )}
            </Link>
          </li>

          <li
            className={`menu-item ${
              !sidebarToggle && isPC && 'w-[30%]'
            }  mb-2 rounded-lg py-2`}
            onClick={() => {
              isPC && setSidebarToggle(true);
            }}
          >
            <Accordion
              expanded={toggleAccordion}
              onChange={handleAccordionToggle}
              sx={{ backgroundColor: 'unset', boxShadow: 'unset' }}
            >
              <AccordionSummary
                sx={{ padding: '0 0', alignItems: 'baseline' }}
                expandIcon={
                  !isPC ? (
                    <ExpandMoreIcon sx={{ color: '#fff' }} />
                  ) : isPC && sidebarToggle ? (
                    <ExpandMoreIcon sx={{ color: '#fff' }} />
                  ) : null
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={handleAccordionToggle}
              >
                <Typography>
                  <div>
                    <div
                      className={`px-3 py-2 text-white flex items-center gap-5 ${
                        !sidebarToggle && isPC && 'justify-center'
                      }`}
                    >
                      <SettingsOutlined className="inline-block !w-5 !h-5" />
                      {isPC ? (
                        <span
                          className={`${
                            sidebarToggle
                              ? 'text-[#C6DDFF] text-sm font-medium'
                              : 'hidden'
                          }`}
                        >
                           {t("setting")}
                        </span>
                      ) : (
                        <span className="text-[#C6DDFF] text-sm font-medium">
                          {t("setting")}
                        </span>
                      )}
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  borderLeft: '1px solid #4F617C',
                  marginLeft: '28px',
                  padding: '0',
                }}
              >
                {/* <Typography>
                  <Link
                    to="/"
                    className="px-3 py-2 text-white flex items-center gap-5"
                  >
                    <span className="text-[#C6DDFF] text-sm font-medium">
                      User management
                    </span>
                  </Link>
                </Typography>

                <Typography>
                  <Link
                    to="/"
                    className="px-3 py-2 text-white flex items-center gap-5"
                  >
                    <span className="text-[#C6DDFF] text-sm font-medium">
                      Role management
                    </span>
                  </Link>
                </Typography> */}

                {/* <Typography>
                  <Link
                    to="/"
                    className="px-3 py-2 text-white flex items-center gap-5"
                  >
                    <span className="text-[#C6DDFF] text-sm font-medium">
                      {t("resetPasswordForTeller")}
                     
                    </span>
                  </Link>
                </Typography> */}

                {/* <Typography>
                  <Link
                    to="/"
                    className="px-3 py-2 text-white flex items-center gap-5"
                  >
                    <span className="text-[#C6DDFF] text-sm font-medium">
                      Policy
                    </span>
                  </Link>
                </Typography> */}
                <Typography>
                  <Link
                    to="/ChangePassword"
                    className="px-3 py-2 text-white flex items-center gap-5"
                  >
                    <span className="text-[#C6DDFF] text-sm font-medium">
                    {t("changePassword")}
                    </span>
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </li>
        </ul>
      </div>

      <div
        className={`mini-sidebar bg-[#1D3455] rounded-lg mx-2 mb-8 ${
          !sidebarToggle && isPC && 'w-fit mx-3 absolute bottom-0 right-0'
        }`}
      >
        <Link
          to="/Profile"
          className="flex items-center  justify-between hover:shadow hover:bg-[#1D3455]"
        >
          <div className="flex items-center gap-3 px-3 py-5">
            <div>
              <img src={Avatar} alt="" />
            </div>
            <div className={!sidebarToggle && isPC && 'hidden'}>
              <p className="text-[15px] font-medium text-white">{fullName}</p>
              <span className="text-sm font-light text-white"> {t("admin")}</span>
            </div>
          </div>
          {sidebarToggle && <ChevronRightOutlinedIcon />}
        </Link>

        <div
          className={`flex items-center border-t-[1px] border-slate-500 border-solid hover:shadow hover:bg-[#1D3455] ${
            !sidebarToggle && isPC && 'flex-col'
          }`}
        >
          <div
            className="flex items-center justify-center gap-2 w-1/2 px-3 py-3 cursor-pointer"
            onClick={handleLogoutClick}
          >
            <LogoutOutlined className="!w-5 !h-5" />
            {isPC ? (
              <span
                className={
                  sidebarToggle ? 'text-sm font-medium text-white' : 'hidden'
                }
              >
                {t("logout")}
              </span>
            ) : (
              <span className="text-sm font-medium text-white">Logout</span>
            )}
          </div>
        </div>
      </div>
      <Dialog open={openLogoutDialog} onClose={handleCloseDialog}>
        <DialogContent>{t("areYouSureYouWantTologout")}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="error"
            style={{ textTransform: 'none', fontWeight: 'bold' }}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleLogout}
            color="primary"
            style={{ textTransform: 'none', fontWeight: 'bold' }}
          >
            {t("logout")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
