import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Login.css";
import Logo from "../../assets/image/logologin.png";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import createAxiosClient from "../../utils/axiosClient";
import { Button, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../features/auth/authSlice";
import { getVariable } from "../../utils/nomal";
import { useTranslation } from "react-i18next";
const Login = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosClient = createAxiosClient(navigate, dispatch);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!username || !password) {
      toast.error("Please enter your account and password");
      setLoading(false); // Dừng loading nếu có lỗi nhập liệu
      return;
    }

    try {
      
      const hashedPassword = CryptoJS.SHA256(password).toString();
      const firebaseToken = getVariable('firebaseToken')
      const body = {
        username: username.toLowerCase(),
        password: hashedPassword,
        firebaseToken,
      };
      const response = await axiosClient.post(`auth/login`, body);

      const Token = response.Token; 
      const UserInfo = response.UserInfo;
      if (Token) {
        localStorage.setItem("authToken", Token);
        localStorage.setItem("UserId", UserInfo.UserId);
        // Dispatch token to Redux store
        dispatch(loginSuccess(Token));

        navigate("/");
      } else {
        throw new Error("Login failed, no token returned");
      }
    } catch (err) {
      console.log("Error: ", err); 
    } finally {
      setLoading(false);
    }
};

  return (
    <div>
      <div className="login-wrapper flex">
        <div className="login-bg"></div>
        <div className="form-login flex flex-col gap-6">
          <div>
            <img src={Logo} alt="Logo" className="logo my-0 mx-auto" />
          </div>
          <form
            className="form-main-login flex flex-col gap-7"
            onSubmit={handleSubmit}
          >
            <p className="text-[20px] text-white font-bold text-center uppercase">
              {t("login")}
            </p>
            <div>
              <div className="mb-5">
                <label
                  htmlFor="username"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  {t("username")}
                </label>
                <div className="custom-input-login">
                  <input
                    id="username"
                    type="text"
                    placeholder="Username"
                    value={username}
                    autoComplete="off"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="text-[14px] text-white font-semibold mb-1 inline-block"
                >
                  {t("password")}
                </label>
                <div className="custom-input-login">
                  <input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {showPassword ? (
                    <VisibilityOutlinedIcon
                      className="input-icon"
                      onClick={() => setShowPassword(false)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      className="input-icon"
                      onClick={() => setShowPassword(true)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
              </div>
              <div className="text-right">
                <a
                  href="/ResetPassword"
                  className="text-white font-semibold hover:underline text-sm"
                >
                  {t("forgotPassword")}?
                </a>
              </div>
            </div>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: loading ? "#FFFFFF" : "#FFFFFF",
                color: "rgba(47, 141, 227, 1)",
                fontWeight: "bold",
                height: "44px",
                borderRadius: "10px",
                textTransform: "none",
                boxShadow: "none", 
                "&:hover": { backgroundColor: loading ? "#FFFFFF" : "#FFFFFF" }, 
                "&:disabled": {
                  backgroundColor: "#DDDDDD", 
                  color: "rgba(47, 141, 227, 1)", 
                },
              }}
              disabled={loading || !username || !password}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Login"
              )}
            </Button>

            <div className="text-center mt-2 mb-1">
              <span className="text-white"> {t("dontHaveAnAccount")}? </span>
              <a
                href="/signup"
                className="text-white font-semibold hover:underline text-sm"
              >
                {t("signUp")}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
