import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
const storedLang = localStorage.getItem("language") || "en";
i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: storedLang,
    resources: {
      en: {
        translation: {
          greeting: "hello, Welcome!",
          tellerPortal: "Teller Portal",
          english: "English",
          burmese: "Burmese",
          vietnam: "Vietnameese",
          dashboard: "Dashboard",
          livechat: "Live chat",
          history: "History",
          setting: "Setting",
          resetPasswordForTeller: "Reset Password For Teller",
          changePassword: "Change Password",
          admin: "Admin",
          logout: "Logout",
          areYouSureYouWantTologout: "Are you sure you want to log out?",
          cancel: "Cancel",
          overview: "Overview",
          popularTopics: "Popular Topics",
          successfulConversation: "Successful conversation",
          avgResponseTime: "Avg response time",
          profilePicture: "Profile picture",
          accountDetail: "Account Detail",
          fullName: "Full Name",
          email: "Email",
          phoneNumber: "Phone number",
          birthday: "Birthday",
          editInformation: "Edit Information",
          uploadNewImage: "Upload new image",
          areYouSureYouWantToJoinThisChat:
            "Are you sure you want to join this chat?",
          startNow: "Start Now",
          pendingChat: "Pending Chat",
          noMessagesYet: "No messages, yet",
          chatHasBeenEnded: "Chat has been ended.",
          noMessagesAvailable: "No messages available",
          holdingChat: "Holding chat",
          recent: "Recent",
          noMessagesAvailable: "No messages available",
          ///
          search: "Search",
          advanceFilter: "Advance Filter",
          clearAll: "Clear all",
          searchByName: "Search by name",
          fromDate: "From date",
          toDate: "To date",
          noResult: "No result",
          //
          currentPassword: "Current password",
          newPassword: "New password",
          confirmPassword: "Confirm password",
          saveChanges: "Save changes",
          enterOldPassword: "Enter old password",
          enterNewPassword: "Enter new password",
          confirmNewPassword: "Enter old password",
          ///
          currentPasswordIsRequired: "Current password is required",
          newPasswordIsRequired: "New password is required",
          confirmPasswordIsRequire: "Confirm password is required",
          confirmPasswordDoesNotMatchNewPassword:
            "Confirm password does not match new password",
          ///
          holdChat: "Hold chat",
          unhold: "Unhold",
          activeNow: "Active now",
          profile: "Profile",
          from: "From",
          digitalBanking: "Digital Banking",
          email: "Email",
          phoneNumber: "Phone Number",
          address: "Address",
          licenseType: "License Type",
          licenseNo: "License No",
          gallery: "Gallery",
          media: "Media",
          files: "Files",
          record: "Record",
          thereIsNoMediaShared: "There is no media shared",
          changeName: "Change name",
          saveName: "Save name",
          nameCannotExceed30Characters: "Name cannot exceed 30 characters",
          nameCannotBeEmpty: "Name cannot be empty",
          saveChanges: "Save changes",
          notifications: "Notifications",
          markAllAsRead: "Mark all as read",
          login: "Login",
          username: "Username",
          password: "Password",
          forgotPassword:"Forgot Password",
          dontHaveAnAccount:"Don’t have an account",
          signUp: "Sign up",
          //
          bySender:"By Sender",
          byUser: "By User",
          byTeller:"By Teller",
        },
      },
      br: {
        translation: {
          greeting: "မင်္ဂလာပါ ကြိုဆိုပါတယ်။",
          tellerPortal: "ပြောသူပေါ်တယ်။",
          english: "အင်္ဂလိပ်",
          burmese: "မြန်မာ",
          vietnam: "ဗီယက်နမ်",
          dashboard: "ဒိုင်ခွက်",
          livechat: "တိုက်ရိုက်ချတ်",
          history: "သမိုင်း",
          setting: "ဆက်တင်",
          resetPasswordForTeller:
            "ဟောပြောသူအတွက် စကားဝှက်ကို ပြန်လည်သတ်မှတ်ပါ။",
          changePassword: "စကားဝှက်ကိုပြောင်းရန်",
          admin: "အက်မင်",
          logout: "ထွက်လိုက်ပါ။",
          areYouSureYouWantTologout: "ထွက်လိုသည်မှာ သေချာပါသလား။",
          cancel: "မလုပ်တော့",
          overview: "ခြုံငုံသုံးသပ်ချက်",
          popularTopics: "လူကြိုက်များသော အကြောင်းအရာများ",
          successfulConversation: "အောင်မြင်သော စကားဝိုင်း",
          avgResponseTime: "ပျမ်းမျှတုံ့ပြန်ချိန်",
          profilePicture: "ပရိုဖိုင်ပုံ",
          accountDetail: "အကောင့်အသေးစိတ်",
          fullName: "နာမည်အပြည့်အစုံ",
          email: "အီးမေးလ်",
          phoneNumber: "ဖုန်းနံပါတ်",
          birthday: "မွေးနေ့",
          editInformation: "အချက်အလက်ကို တည်းဖြတ်ပါ။",
          uploadNewImage: "ပုံအသစ်ကို အပ်လုဒ်လုပ်ပါ။",
          areYouSureYouWantToJoinThisChat:
            "ဤချတ်တွင် ပါဝင်လိုသည်မှာ သေချာပါသလား။",
          startNow: "ယခုစတင်ပါ။",
          pendingChat: "စောင့်ဆိုင်းနေသော ချတ်",
          noMessagesYet: "မက်ဆေ့ချ်များ မရှိသေးပါ။",
          chatHasBeenEnded: "စကားစမြည် ပြီးသွားပါပြီ။",
          noMessagesAvailable: "မက်ဆေ့ချ်များ မရရှိနိုင်ပါ။",
          holdingChat: "စကားပြောခန်းကို ကိုင်ထားသည်။",
          recent: "မကြာမှီ",
          noMessagesAvailable: "မက်ဆေ့ချ်များ မရရှိနိုင်ပါ။",
          advanceFilter: "အဆင့်မြင့် စစ်ထုတ်မှု",
          clearAll: "အရာအားလုံးကိုရှင်းပါ။",
          searchByName: "အမည်ဖြင့်ရှာဖွေပါ။",
          fromDate: "ရက်စွဲမှ",
          toDate: "ယနေ့အထိ",
          noResult: "ရလဒ်မရှိပါ။",
          search: "ရှာဖွေ",
          currentPassword: "လက်ရှိ စကားဝှက်",
          newPassword: "စကားဝှက်အသစ်",
          confirmPassword: "စကားဝှက်အတည်ပြုခြင်း",
          saveChanges: "အပြောင်းအလဲများကို သိမ်းဆည်းပါ။",
          enterOldPassword: "စကားဝှက်ဟောင်းကို ရိုက်ထည့်ပါ။",
          enterNewPassword: "စကားဝှက်အသစ်ထည့်ပါ။",
          confirmNewPassword: "စကားဝှက်ဟောင်းကို ရိုက်ထည့်ပါ။",
          currentPasswordIsRequired: "လက်ရှိ စကားဝှက် လိုအပ်ပါသည်။",
          newPasswordIsRequired: "စကားဝှက်အသစ် လိုအပ်သည်။",
          confirmPasswordIsRequire: "စကားဝှက်ကို အတည်ပြုရန် လိုအပ်ပါသည်။",
          confirmPasswordDoesNotMatchNewPassword:
            "စကားဝှက်အသစ်သည် စကားဝှက်အသစ်နှင့် မကိုက်ညီကြောင်း အတည်ပြုပါ။",
          ///
          holdChat: "ချတ်ကို ကိုင်ထားပါ။",
          unhold: "ဖြုတ်လိုက်ပါ။",
          activeNow: "အသက်ဝင်နေပြီ။",
          profile: "ကိုယ်ရေးအကျဉ်း",
          from: "ထံမှ",
          digitalBanking: "ဒစ်ဂျစ်တယ်ဘဏ်လုပ်ငန်း",
          email: "အီးမေးလ်",
          address: "လိပ်စာ",
          phoneNumber: "ဖုန်းနံပါတ်",
          licenseType: "လိုင်စင်အမျိုးအစား",
          licenseNo: "လိုင်စင်နံပါတ်",
          gallery: "ပြခန်း",
          media: "မီဒီယာ",
          files: "ဖို",
          record: "မှတ်တမ်း",
          thereIsNoMediaShared: "မီဒီယာတွေမှာ မျှဝေထားတာ မရှိပါဘူး။",
          changeName: "เปลี่ยนชื่อ",
          saveName: "บันทึกชื่อ",
          nameCannotExceed30Characters: "ชื่อไม่สามารถเกิน 30 ตัวอักษร",
          nameCannotBeEmpty: "ชื่อไม่สามารถเว้นว่างได้",
          notifications: "အကြောင်းကြားစာများ",
          markAllAsRead: "အားလုံးဖတ်ပြီးဟုအမှတ်အသားပြုရန်",
          login: "ဝင်ရောက်ရန်",
          username: "အသုံးပြုသူအမည်",
          password: "စကားဝှက်",
          forgotPassword: "စကားဝှက်မေ့နေပါသလား",
          dontHaveAnAccount: "အကောင့်မရှိဘူးလား",
          signUp: "စာရင်းသွင်းပါ",
          bySender: "ပို့ဆောင်သူအားဖြင့်",
          byUser: "အသုံးပြုသူအားဖြင့်",
          byTeller: "ငွေလွှဲသူအားဖြင့်"
        },
      },
      vi: {
        translation: {
          greeting: "Xin chào, Chào mừng!",
          tellerPortal: "Cổng giao dịch viên",
          english: "Tiếng Anh",
          burmese: "Tiếng Myanmar",
          vietnam: "Tiếng Việt",
          dashboard: "Bảng điều khiển",
          livechat: "Trò chuyện trực tiếp",
          history: "Lịch sử",
          setting: "Cài đặt",
          resetPasswordForTeller: "Đặt lại mật khẩu cho giao dịch viên",
          changePassword: "Đổi mật khẩu",
          admin: "Quản trị viên",
          logout: "Đăng xuất",
          areYouSureYouWantTologout: "Bạn có chắc chắn muốn đăng xuất không?",
          cancel: "Hủy",
          overview: "Tổng quan",
          popularTopics: "Chủ đề phổ biến",
          successfulConversation: "Cuộc trò chuyện thành công",
          avgResponseTime: "Thời gian phản hồi trung bình",
          profilePicture: "Ảnh đại diện",
          accountDetail: "Chi tiết tài khoản",
          fullName: "Họ và tên",
          email: "Email",
          phoneNumber: "Số điện thoại",
          birthday: "Ngày sinh",
          editInformation: "Chỉnh sửa thông tin",
          uploadNewImage: "Tải ảnh mới",
          areYouSureYouWantToJoinThisChat:
            "Bạn có chắc chắn muốn tham gia cuộc trò chuyện này không?",
          startNow: "Bắt đầu ngay",
          pendingChat: "Trò chuyện đang chờ",
          noMessagesYet: "Chưa có tin nhắn",
          chatHasBeenEnded: "Cuộc trò chuyện đã kết thúc.",
          noMessagesAvailable: "Không có tin nhắn nào",
          holdingChat: "Đang giữ cuộc trò chuyện",
          recent: "Gần đây",
          search: "Tìm kiếm",
          advanceFilter: "Lọc nâng cao",
          clearAll: "Xóa tất cả",
          searchByName: "Tìm kiếm theo tên",
          fromDate: "Từ ngày",
          toDate: "Đến ngày",
          noResult: "Không có kết quả",
          currentPassword: "Mật khẩu hiện tại",
          newPassword: "Mật khẩu mới",
          confirmPassword: "Xác nhận mật khẩu",
          saveChanges: "Lưu thay đổi",
          enterOldPassword: "Nhập mật khẩu cũ",
          enterNewPassword: "Nhập mật khẩu mới",
          confirmNewPassword: "Xác nhận mật khẩu mới",
          currentPasswordIsRequired: "Mật khẩu hiện tại là bắt buộc",
          newPasswordIsRequired: "Mật khẩu mới là bắt buộc",
          confirmPasswordIsRequire: "Xác nhận mật khẩu là bắt buộc",
          confirmPasswordDoesNotMatchNewPassword:
            "Xác nhận mật khẩu không khớp với mật khẩu mới",
          holdChat: "Giữ cuộc trò chuyện",
          unhold: "Bỏ giữ",
          activeNow: "Đang hoạt động",
          profile: "Hồ sơ",
          from: "Từ",
          digitalBanking: "Ngân hàng số",
          email: "Email",
          phoneNumber: "Số điện thoại",
          address: "Địa chỉ",
          licenseType: "Loại giấy phép",
          licenseNo: "Số giấy phép",
          gallery: "Bộ sưu tập",
          media: "Phương tiện",
          files: "Tệp",
          record: "Bản ghi",
          thereIsNoMediaShared: "Không có phương tiện nào được chia sẻ",
          changeName: "Đổi tên",
          saveName: "Lưu tên",
          nameCannotExceed30Characters: "Tên không được vượt quá 30 ký tự",
          nameCannotBeEmpty: "Tên không được để trống",
          notifications: "Thông báo",
    markAllAsRead: "Đánh dấu tất cả đã đọc",

    login: "Đăng nhập",
    username: "Tên tài khoản",
    password: "Mật khẩu",
    forgotPassword: "Quên mật khẩu",
    dontHaveAnAccount: "Chưa có tài khoản",
    signUp: "Đăng ký",
    bySender: "Theo người gửi",
    byUser: "Theo người dùng",
    byTeller: "Theo giao dịch viên"
        },
      },
    },
  });

export default i18next;
