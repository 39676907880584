import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatSendMessage from './ChatSendMessage';
import ChatApproveMessage from './ChatApproveMessage';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import NoMess from '../../assets/image/nomess.png';
import Info from '../../assets/image/info.png';
import EndChat from '../../assets/image/EndChat.png';
import Bot from '../../assets/image/bot.png';
import { Button, Popover } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { setEndChat, setListMess } from '../Redux/connectionSlice';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DescriptionIcon from '@mui/icons-material/Description';
import '../../css/ChatDetail.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import createAxiosClient from '../../utils/axiosClient';
import { setMediaGallery } from '../Redux/connectionSlice';
import LeftChat from '../../assets/image/leftchat.svg';
import JoinChat from '../../assets/image/joinchat.svg';
import { useTranslation } from "react-i18next";
function ChatDetail({
  data,
  dataTab,
  onStartChat,
  typeChatToggle,
  setTypeChatToggle,
  toggleUserInfo,
  setToggleUserInfo,
  onRefreshLiveChats,
  activeTab,
}) {
  const { t } = useTranslation();
  const [visibleMessageTimes, setVisibleMessageTimes] = useState({});
  const connection = useSelector((state) => state.connection.connection);
  const isEndChat = useSelector((state) => state.connection.endChat);
  const chatRoomIdActive = useSelector(
    (state) => state.connection.chatRoomIdActive
  );
  const listMess = useSelector((state) => state.connection.listMess);
  const [displayData, setDisplayData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const endOfMessagesRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosClient = createAxiosClient(navigate);
  const [isHold, setIsHold] = useState(data?.IsHold);
  const [roomId, setRoomId] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    setToggleUserInfo(false);
  }, [setToggleUserInfo]);

  useEffect(() => {
    if (connection) {
      connection.on(
        'ReceiveMessage',
        (roomId, connectionId, message, attachFiles, id) => {
          setRoomId(roomId);
          setMessage(message);
          if (roomId === localStorage.getItem('roomIdActive')) {
            if (connection.connectionId === connectionId) {
              setDisplayData((prevData) => [
                ...prevData,
                {
                  type: 'message',
                  role: 'teller',
                  message: message,
                  time: new Date().toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  }),
                  attachFiles: attachFiles,
                },
              ]);
            } else {
              setDisplayData((prevData) => [
                ...prevData,
                {
                  type: 'message',
                  role: 'user',
                  message: message,
                  time: new Date().toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  }),
                  attachFiles: attachFiles,
                },
              ]);
            }
          }
        }
      );
      connection.on('ReceiveMessageNotice', (roomId, message) => {
        // debugger;
        setRoomId(roomId);
        setMessage(message);
        setDisplayData((prevData) => [
          ...prevData,
          {
            type: 'notice',
            role: 'notice',
            contentType: 'Notice',
            message: message,
            time: new Date().toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            }),
          },
        ]);
      });
    }

    return () => {
      if (connection) {
        connection.off('ReceiveMessage');
        connection.off('ReceiveMessageNotice');
      }
    };
  }, [connection]);

  useEffect(() => {
    if (listMess?.LiveChat) {
      dispatch(
        setListMess(
          updateRoomIdInList(listMess, roomId, message, chatRoomIdActive)
        )
      );
    }
  }, [message]);

  useEffect(() => {
    if (data?.ChatData) {
      const sortedChatData = data.ChatData.map((chat) => ({
        ...chat,
        date: new Date(chat.Timestamp).toLocaleDateString(),
        time: new Date(chat.Timestamp).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
      })).sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

      const chatArr = sortedChatData.map((chat, index) => ({
        type: 'message',
        role: chat.Role,
        message: chat.Message,
        time: chat.time,
        contentType: chat.ContentType,
        attachFiles: chat.AttachFile
          ? chat.AttachFile.map((file) => ({
              mediaId: file.MediaId,
              name: file.Name,
              type: file.Type,
              url: file.Url,
            }))
          : [],
        isLastBotMessage:
          index === sortedChatData.length - 1 ||
          sortedChatData[index + 1].Role !== chat.Role,
      }));

      setDisplayData(chatArr);
      setIsHold(data?.IsHold);
    }

    if (data?.RoomId) {
      const { LiveChat } = listMess;
      const newDataHoldingChat = [...LiveChat.DataHoldingChat];
      const newData = [...LiveChat.Data];

      const holdingIndex = newDataHoldingChat.findIndex(
        (chat) => chat.RoomId === data.RoomId
      );
      if (holdingIndex !== -1) {
        newDataHoldingChat[holdingIndex] = {
          ...newDataHoldingChat[holdingIndex],
          MessUnread: 0,
        };
      } else {
        const index = newData.findIndex((chat) => chat.RoomId === data.RoomId);
        if (index !== -1) {
          newData[index] = {
            ...newData[index],
            MessUnread: 0,
          };
        }
      }

      dispatch(
        setListMess({
          ...listMess,
          LiveChat: {
            ...LiveChat,
            DataHoldingChat: newDataHoldingChat,
            Data: newData,
          },
        })
      );
    }
  }, [data]);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [displayData]);
  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'auto' });
  });

  const updateRoomIdInList = (listMess, roomId, message, chatRoomIdActive) => {
    const { LiveChat } = listMess;
    const newDataHoldingChat = [...LiveChat.DataHoldingChat];
    const newData = [...LiveChat.Data];

    const holdingIndex = newDataHoldingChat.findIndex(
      (chat) => chat.RoomId === roomId
    );
    if (holdingIndex !== -1) {
      newDataHoldingChat[holdingIndex] = {
        ...newDataHoldingChat[holdingIndex],
        MaskMess: message,
        MessUnread:
          !chatRoomIdActive || chatRoomIdActive !== roomId
            ? newDataHoldingChat[holdingIndex].MessUnread + 1
            : 0,
      };
      const [updatedHoldingChat] = newDataHoldingChat.splice(holdingIndex, 1);
      newDataHoldingChat.unshift(updatedHoldingChat);
    } else {
      const index = newData.findIndex((chat) => chat.RoomId === roomId);
      if (index !== -1) {
        newData[index] = {
          ...newData[index],
          MaskMess: message,
          MessUnread:
            !chatRoomIdActive || chatRoomIdActive !== roomId
              ? newData[index].MessUnread + 1
              : 0,
        };
        const [updatedChat] = newData.splice(index, 1);
        newData.unshift(updatedChat);
      }
    }

    return {
      ...listMess,
      LiveChat: {
        ...LiveChat,
        DataHoldingChat: newDataHoldingChat,
        Data: newData,
      },
    };
  };

  const handleToggleUserInfo = () => {
    setToggleUserInfo((prev) => !prev);
  };

  const handleMessageClick = (index) => {
    setVisibleMessageTimes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchMediaData = async () => {
    try {
      const body = {
        RoomId: data.RoomId,
      };
      const result = await axiosClient.post('/chatroom/moreinfo', body);
      result && dispatch(setMediaGallery(result));
    } catch (error) {
      console.error('Error fetching media data:', error);
    }
  };

  const handleInfoClick = () => {
    if (!toggleUserInfo) {
      fetchMediaData();
    }
    handleClose();
    handleToggleUserInfo();
  };

  const handleHold = async () => {
    try {
      let body = { RoomId: data.RoomId, HoldingChat: !isHold };
      const result = await axiosClient.post('/chatroom/updateholding', body);
      if (result) {
        setIsHold(!isHold);
        onRefreshLiveChats();
      }
    } catch (error) {
      console.error('Error holding chat:', error);
    }
  };

  const handleEndChatClick = async () => {
    try {
      const body = { RoomId: data.RoomId };
      const result = await axiosClient.post('/chatroom/endchat', body);
      dispatch(setEndChat(true));
      handleClose();
      setDisplayData([]);
    } catch (error) {
      console.error('Error ending chat:', error);
    }
  };

  if (
    !data ||
    !Array.isArray(data.ChatData) ||
    data.ChatData.length === 0 ||
    displayData.length === 0
  ) {
    return (
      <div
        className={`chat-right ${
          typeChatToggle ? '' : 'active'
        } absolute right-[12px] transition-all duration-300 ease-in-out no-messages`}
      >
        <div className="chat-detail-main flex flex-col items-center justify-center">
          <img src={NoMess} alt="No messages" className="w-[150px] h-[150px]" />
          <div>
            <span className="text-xl font-bold"> {t("noMessagesYet")}</span>
            <span className="loader-nochat"></span>
          </div>
        </div>
      </div>
    );
  }
  if (isEndChat) {
    return (
      <div className="chat-end-message">
        <img src={EndChat} alt="Chat ended" className="w-[150px] h-[150px]" />
        <div>
          <span className="text-xl font-bold">{t("chatHasBeenEnded")}</span>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`chat-right ${
        typeChatToggle ? '' : 'active'
      } absolute right-[12px] transition-all duration-300 ease-in-out`}
    >
      <div className="chat-detail-header flex justify-between">
        <div className="flex items-center gap-4">
          <div className="relative chat-item-avatar">
            <div class="flex justify-center items-center w-10 h-10 border-0 rounded-full">
              <img
                src={data?.UserInfo?.Image}
                alt="avatar-mage"
                class="w-10 h-10 rounded-full"
              />
            </div>
            <div class="flex justify-center items-center w-[18px] h-[18px] rounded-full absolute bottom-0 right-0 bg-white">
              <img
                src={data?.UserInfo?.ImageChannel}
                alt="avatar-image-channel"
                class="w-[18px] h-[18px] rounded-full avatar-image-channel"
              />
            </div>
          </div>
          <div className="chat-item-info">
            <p className="text-base font-medium leading-6 text-left pb-[2px] text-[#1E1E1E]">
              {data?.UserInfo?.NickName || data?.UserInfo?.Name}
            </p>
            <p
              className={`relative text-xs font-normal leading-4 text-left text-[#6A6A73] pl-3 chat-status ${
                data?.UserInfo?.Status
                  ? data.UserInfo.Status.replace(/\s+/g, '').toLowerCase()
                  : ''
              }`}
            >
              {data?.UserInfo?.Status}
            </p>
          </div>
          {activeTab === 'live' ? (
            <button
              className={`block text-[var(--blue-1)] text-[13px] font-medium leading-6 text-left ml-2 button-pin ${
                !isHold ? 'active' : ''
              }`}
              onClick={handleHold}
            >
              <PushPinIcon /> {isHold ? t('unhold') : t('holdChat')}
            </button>
          ) : null}
        </div>
        {/* <div onClick={handleClick} className="cursor-pointer">
          <MoreVertIcon />
        </div> */}
        <div className="flex items-center gap-2">
          <AccountCircleOutlinedIcon
            onClick={handleInfoClick}
            className="cursor-pointer"
            style={{ color: '#6A6A73' }}
          />
          {activeTab === 'live' ? (
            <LogoutOutlinedIcon
              onClick={handleEndChatClick}
              className="cursor-pointer end-chat-icon"
              style={{ color: '#EA614E' }}
            />
          ) : null}
        </div>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: { width: '200px', padding: '16px', borderRadius: '8px' },
        }}
      ></Popover>

      <div
        className={`chat-detail-main ${
          toggleUserInfo ? 'activeUserInfo' : 'right-0'
        }`}
      >
        <ul className="custom-scrollbar-chat">
          {displayData.map((item, index) =>
            item.type === 'date' ? (
              <div key={index} className="chat-date">
                {item.date}
              </div>
            ) : (
              <li
                key={index}
                className={
                  item.contentType === 'Notice'
                    ? 'notice flex'
                    : item.role === 'user'
                    ? 'receiver'
                    : 'sender'
                }
              >
                <img
                  src={
                    item?.message?.includes('left')
                      ? LeftChat
                      : item?.message?.includes('joined')
                      ? JoinChat
                      : ''
                  }
                  alt="noimage"
                  className={
                    item.contentType !== 'Notice' ? 'hidden' : 'notice-image'
                  }
                />
                <span
                  className={`${
                    visibleMessageTimes[index]
                      ? 'opacity-100 translate-y-0'
                      : 'opacity-100 translate-y-0'
                  } transition-all duration-300 ${
                    item.contentType === 'Notice'
                      ? 'hidden'
                      : item.role === 'user'
                      ? 'receiver-message-time'
                      : 'sender-message-time'
                  }`}
                >
                  {item.role === 'user'
                    ? data?.UserInfo?.Name
                    : item.role === 'bot'
                    ? 'AriTalk'
                    : `(Teller) ${localStorage.getItem('nameTeller') || ''}`}
                  , {' ' + item.time}
                </span>
                <div
                  className={
                    item.contentType === 'Notice'
                      ? 'notice-message'
                      : item.role === 'user'
                      ? 'receiver-message'
                      : item.role === 'bot'
                      ? 'bot-message'
                      : 'sender-message'
                  }
                  onClick={() => handleMessageClick(index)}
                >
                  {item.role === 'bot' && item.isLastBotMessage && (
                    <div className="bot-avatar">
                      <img
                        src={Bot}
                        alt="Bot Avatar"
                        className="w-[20px] h-[20px] rounded-full"
                      />
                    </div>
                  )}

                  {item.message}
                  {item.attachFiles?.length > 0 && (
                    <div className="attachment-section">
                      {item.attachFiles.map((file, fileIndex) => (
                        <div key={fileIndex} className="attachment-item">
                          {file.type && file.type.startsWith('image/') ? (
                            <img
                              src={file.url}
                              alt={file.name}
                              className="attachment-image"
                            />
                          ) : file.type && file.type.startsWith('video/') ? (
                            <video
                              controls
                              src={file.url}
                              className="attachment-video"
                            >
                              Your browser does not support the video tag.
                            </video>
                          ) : file.type && file.type.startsWith('audio/') ? (
                            <audio
                              controls
                              src={file.url}
                              className="attachment-audio"
                            >
                              Your browser does not support the audio tag.
                            </audio>
                          ) : file.type && file.type.startsWith('audio/') ? (
                            <audio
                              controls
                              src={file.url}
                              className="attachment-audio"
                            >
                              Your browser does not support the audio tag.
                            </audio>
                          ) : (
                            // Hiển thị biểu tượng và link tải nếu không phải ảnh/video
                            <>
                              <div className="block-icon-file">
                                <DescriptionIcon sx={{ color: '#000' }} />
                              </div>
                              <a
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="attachment-file"
                                style={{
                                  color: '#000',
                                  textDecoration: 'none',
                                }}
                              >
                                {file.name || 'Download File'}
                              </a>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </li>
            )
          )}
          <div ref={endOfMessagesRef} />
        </ul>
        {dataTab === 'live' ? (
          <ChatSendMessage data={data} />
        ) : (
          <ChatApproveMessage onStartChat={onStartChat} data={data} />
        )}
      </div>
    </div>
  );
}

export default ChatDetail;
